import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaArrowUp, FaSearch } from "react-icons/fa";
import {useSelector, useDispatch} from "react-redux";
import React, { useState } from "react";
import { getImageUrl } from "../../../../Utils";
import Loader from "../../../Ui/Loader";
import { Link } from "react-router-dom";

import { getCouponByNameAction } from "../../../../Store/Coupons/actions";
import { getStoresByNameAction } from "../../../../Store/Stores/actions";

function NavScrollExample() {

  const dispatch = useDispatch();

    const {
    domainData,
    loading: loadingWebsiteData,
  } = useSelector((state) => state.Website);

  const { categoryList } = useSelector((state) => state.Category);


 const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (searchValue) {
      dispatch(getStoresByNameAction({ name: searchValue, perPage: 1000 }));

      dispatch(getCouponByNameAction({ name: searchValue }));
    }

    enterSearch.current.click();
  };

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  
  window.addEventListener("scroll", toggleVisible);

  const enterSearch = React.useRef();

  return (
    <>
    <Navbar id="nav-i2" style={{background:"#59063d", color:"#ffeaf6", 
    position:"fixed", zIndex:"999", width:"100vw"}} expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">{loadingWebsiteData ? (
              <Loader
                customStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <Link to="/">
                <img
                  src={getImageUrl(domainData?.logo, "logo512.png")}
                  width="70"
                  height="45"
                  loading="lazy"
                  className="d-inline-block align-top"
                  alt="logo"
                />
              </Link>
            )}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" style={{color:"white", backgroundColor:"#b80979"}}/>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link style={{color:"#ffeaf6"}} href="/">Home</Nav.Link>
            <Nav.Link  style={{color:"#ffeaf6"}} href="/stores">Stores</Nav.Link>
            <Nav.Link style={{color:"#ffeaf6"}} >
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", color:"#ffeaf6"}}
                  >
                    Categories
                  </Link>
                  </Nav.Link>
            <Nav.Link href="/aboutus" style={{color:"#ffeaf6"}}>
              About us
            </Nav.Link>
            <Nav.Link href="/contactus" style={{color:"#ffeaf6"}}>
              Contact us
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
            className="shadow-none"
              id="serach-input-index2"
              type="search"
              placeholder="Search"
              style={{borderRadius:"5px 0 0 5px", color:"#59063d", border:"2px solid #ffeaf6"}}
              aria-label="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  
                e.preventDefault();
                  handleSearch();
                }
              }}
              value={searchValue}
            />
            <Link to="/searchpage" style={{
                    padding: "0",}}>
                <button
                  ref={enterSearch}
                  style={{
                    backgroundColor: "#b80979",
                    borderColor: "#b80979",
                    borderRadius: "0 5px 5px 0",
                  }}
                  onClick={handleSearch}
                  class="btn btn-danger shadow-none"
                  type="button"
                >
                  <FaSearch style={{ fontSize: "24px", color:"#ffeaf6" }} />
                </button>
              </Link>
          </Form>
        </Navbar.Collapse>
        
    <button
      id="show-code-button"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1rem",
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <FaArrowUp/>
      </button>
      </Container>
    </Navbar>
    <Navbar id="nav-i2" style={{background:"#59063d", color:"#ffeaf6"}} expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">{loadingWebsiteData ? (
              <Loader
                customStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <Link to="/">
                <img
                  src={getImageUrl(domainData?.logo, "logo512.png")}
                  width="70"
                  height="45"
                  className="d-inline-block align-top"
                  alt="logo"
                />
              </Link>
            )}</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link style={{color:"#ffeaf6"}} href="/">Home</Nav.Link>
            <Nav.Link  style={{color:"#ffeaf6"}} href="/stores">Stores</Nav.Link>
            <NavDropdown  style={{color:"#ffeaf6"}} title="Categories" id="navbarScrollingDropdown">
              {categoryList?.length > 0 &&
                  categoryList?.slice(0, 5).map((category, key) => (

                    <span style={{backgroundColor:"green", width:"200%"}}>
                    <NavDropdown.Item id="category-dropdown-item">
                      <Link
                        key={key}
                        id="category-dropdown"
                        to={`/category/${category.category}`}
                        style={{
                        }}
                      >
                        {category?.category}
                      </Link>
                    </NavDropdown.Item>
                    
                      </span>
                  ))}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/category">
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", color: "rgba(0,0,0,.55)" }}
                  >
                    {" "}
                    Browse all Categories
                  </Link>
                </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/aboutus">
              About us
            </Nav.Link>
            <Nav.Link href="/contactus">
              Contact us
            </Nav.Link>
          </Nav>
          <Form className="d-flex">
            <Form.Control
            className="shadow-none"
              id="serach-input-index2"
              type="search"
              placeholder="Search"
              style={{borderRadius:"5px 0 0 5px", color:"#ffeaf6", border:"2px solid #ffeaf6"}}
              aria-label="Search"
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              value={searchValue}
            />
            <Link to="/searchpage" style={{
                    padding: "0",}}>
                <button
                  ref={enterSearch}
                  style={{
                    backgroundColor: "#b80979",
                    borderColor: "#ffeaf6",
                    borderRadius: "0 5px 5px 0",
                  }}
                  onClick={handleSearch}
                  class="btn btn-danger shadow-none"
                  type="button"
                >
                  <FaSearch style={{ fontSize: "1.3rem", color:"#ffeaf6" }} />
                </button>
              </Link>
          </Form>
        </Navbar.Collapse>
        
    <button
      id="show-code-button"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1rem",
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <FaArrowUp/>
      </button>
      </Container>
    </Navbar>
    </>
  );
}

export default NavScrollExample;