import * as actionTypes from "./actionTypes";

export const getStoresBasedOnDomainAction = () => ({
  type: actionTypes.GET_STORES_BASED_ON_DOMAIN,
});

export const getNewlyAddedStores = () => {
  return {
    type: actionTypes.GET_NEWLY_ADDED_STORES,
  };
};

export const getTopVisitedStores = () => {
  return {
    type: actionTypes.GET_TOP_VISITED_STORES,
  };
};

export const getStoreByIdAction = (payload) => ({
  type: actionTypes.GET_STORE_BY_ID,
  payload,
});

export const getCouponsStoreByIdAction = (payload) => {
  return {
    type: actionTypes.GET_COUPONS_BY_STORE_ID,
    payload,
  };
};

export const getStoresByNameAction = (payload) => {
  return {
    type: actionTypes.GET_STORES_BY_NAME,
    payload,
  };
};
