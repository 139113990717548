import {lazy} from "react";

const Home = lazy(()=>import("../Components/Pages/Home/HomeIndex"));
const Stores = lazy(() => import("../Components/Pages/Stores/index"));
const Store = lazy(() => import("../Components/Pages/Store/index"));
const Category = lazy(() => import("../Components/Pages/Category"));
const Categories = lazy(() => import("../Components/Pages/Categories"));
const Aboutus = lazy(() => import("../Components/Pages/AboutUs"));
const PrivacyPolicy = lazy(() => import("../Components/Pages/PrivacyPolicy"));
const Contact = lazy(() => import("../Components/Pages/Contact/Contact"));
const Couponsredirect = lazy(() => import("../Components/Ui/CouponRedirect.jsx"));
const MetaRedirect = lazy(() => import("../Components/Ui/MetaRedirect"));
const SearchPage = lazy(() => import("../Components/SearchPage/SearchPage.jsx"));
const LatestStore = lazy(() => import("../Components/Pages/LatestStore/index.jsx"));
const NewHome = lazy(() => import("../Components/Pages/Home/index2/index2"));
const Home2 = lazy(() => import("../Components/Pages/Home2/index"));
const Home3 = lazy(() => import("../Components/Pages/Home3/index"));
const Home4 = lazy(() => import("../Components/Pages/Home/index4/index2"));
const NewHome2 = lazy(() => import("../Components/Pages/Home/index3/index2"));
const ZeroRedirect = lazy(()=> import ("../Components/Ui/ZeroRedirect"));

const authProtectedRoutes = [
  { path: "/", exact: true, component: Home, key: 1 },
  { path: "/stores", exact: true, component: Stores, key: 2 },
  { path: "/store/:storeName", exact: true, component: Store, Key: 3 },
  { path: "/category", exact: true, component: Category, Key: 4 },
  { path: "/category/:category", exact: true, component: Categories, Key: 5 },
  { path: "/aboutus", exact: true, component: Aboutus, key: 6 },
  { path: "/privacypolicy", exact: true, component: PrivacyPolicy, key: 7 },
  { path: "/contactus", exact: true, component: Contact, key: 8 },
  { path: "/searchpage", exact: true, component: SearchPage, key: 9 },
  { path: "/lateststore", exact: true, component: LatestStore, key: 10 },
  { path: "/mr", exact: true, component: MetaRedirect, key: 11 },
  {
    path: "/mr/store/:storeName/coupon/:couponId/:redirectionType",
    exact: true,
    component: MetaRedirect,
    key: 12,
  },
  {
    path: "/mr/:link",
    exact: true,
    component: MetaRedirect,
    key: 12,
  },
  { path: "/new-home", exact: true, component: NewHome, key: 13 },
  { path: "/new-home2", exact: true, component: NewHome2, key: 141 },



  {
    path: "/couponsredirect/:storeName/:couponId",
    exact: true,
    component: Couponsredirect,
    key: 12,
  },
  {
    path: "/couponsredirect/category/:category/coupon/:storeName/:couponId",
    exact: true,
    component: Couponsredirect,
    key: 13,
  },
  {
    path: "/couponzr/store/:storeSlug/coupon/:storeName/:couponId",
    exact: true,
    component: ZeroRedirect,
    key: 20,
  },
  {
    path: "/couponsredirect/coupon/:storeName/:couponId",
    exact: true,
    component: Couponsredirect,
    key: 14,
  },
  {
    path: "/couponsredirect/store/:storeSlug/coupon/:storeName/:couponId",
    exact: true,
    component: Couponsredirect,
    key: 15,
  },
  { path: "/home2", exact: true, component: Home2, key: 16 },
  { path: "/home3", exact: true, component: Home3, key: 17},
  { path: "/home4", exact: true, component: Home4, key: 18},
  {
    path: "/couponsredirect/store/:storeSlug/coupon/:storeName/:couponId/:mtrd",
    exact: true,
    component: Couponsredirect,
    pageId: 21,
  },
  {
    path: "/couponsredirect/store/:storeSlug/coupon/:storeName/:couponId/:s2s",
    exact: true,
    component: Couponsredirect,
    pageId: 27,
  },
];

export { authProtectedRoutes };
