import {
  GET_COUPON_URL_FAILURE,
  GET_COUPON_URL_SUCCESS,
  RESET_COUPON_URL,
  SET_COUPON_URL_LOADING,
} from "./actionTypes";

const initialState = {
  link: "",
  error: false,
  errorMessage: "",
  loading: false,
  linkSuccess: false,
};

const CouponUrl = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUPON_URL_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
        error: false,
      };

    case RESET_COUPON_URL:
      return {
        ...initialState,
      };

    case GET_COUPON_URL_SUCCESS:
      return {
        ...state,
        link: action.link,
        loading: false,
        linkSuccess : action.success,
      };
    case GET_COUPON_URL_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        error: true,
        loading: false,
      };

    default:
      return { ...state };
  }
};

export default CouponUrl;
