import { put, all, takeLatest, fork } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../Config/axiosInstance";
import { stringifyErrorMessage } from "../../Utils";

function* getCouponByIdSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_COUPON_BY_ID_LOADING });
    const { data } = yield API.get("/api/end-user/coupon/coupon-by-id", {
      params: payload,
    });

    yield put({
      type: actionTypes.GET_COUPON_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPON_BY_ID_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

function* getEndUserSaga({ payload }) {
  const endUserDataSession = JSON.parse(
    window.sessionStorage.getItem("endUserDataSession")
  );
  if (endUserDataSession) {
    yield put({
      type: actionTypes.GET_END_USER_SUCCESS,
      payload: endUserDataSession,
    });
  } else {
    try {
      yield put({ type: actionTypes.SET_COUPON_BY_ID_LOADING });
      const { data } = yield API.get(
        "end-user/advertisement/getAdBannerByDomain"
      );
      if (data.data) {
        window.sessionStorage.setItem(
          "endUserDataSession",
          JSON.stringify(data.data)
        );
        yield put({
          type: actionTypes.GET_END_USER_SUCCESS,
          payload: data.data,
        });
      } else {
        yield put({
          type: actionTypes.GET_END_USER_FAILURE,
          payload: data,
        });
      }
    } catch (error) {
      yield put({ type: actionTypes.GET_END_USER_FAILURE });
    }
  }
}

function* getCouponByNameSaga({ payload }) {
  payload = {
    ...payload,
    resolution: `${window.innerWidth}x${window.innerHeight}`,
  };
  try {
    yield put({ type: actionTypes.SET_COUPON_BY_ID_LOADING });
    const { data } = yield API.get("end-user/coupon/search-coupon", {
      params: payload,
    });

    yield put({
      type: actionTypes.GET_COUPON_BY_NAME_SUCCESS,
      search: payload,
      payload: data.data,
      totalRecords: data.totalRecords,
      total_pages: data.total_pages,
      per_page: data.per_page,
      page: data.page,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPON_BY_NAME_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

function* getCouponByWebsiteSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_COUPON_BY_ID_LOADING });
    const { data } = yield API.get("/coupon/coupon-list-by-website", {
      params: payload,
    });
    yield put({
      type: actionTypes.GET_COUPON_LIST_BY_WEBSITE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPON_LIST_BY_WEBSITE_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getActiveStoresBasedOnDomainSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_COUPON_BY_ID_LOADING });
    const { data } = yield API.get("/store/getActiveStoresBasedOnDomain", {
      params: payload,
    });

    yield put({
      type: actionTypes.GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getRecommendBannerSaga({ payload }) {
  let recommendBannerByDomainSession = window.sessionStorage.getItem(
    "recommendBannerByDomainSession"
  );
  if (recommendBannerByDomainSession) {
    recommendBannerByDomainSession = JSON.parse(recommendBannerByDomainSession);
  }
  if (recommendBannerByDomainSession?.data) {
    yield put({
      type: actionTypes.RECOMMEND_BANNER_BY_DOMAIN_SUCCESS,
      payload: recommendBannerByDomainSession.data,
    });
  } else {
    try {
      yield put({ type: actionTypes.SET_COUPON_BY_ID_LOADING });
      const { data } = yield API.get(
        "/end-user/advertisement/recommendBannerByDomain",
        {
          params: payload,
        }
      );

      if (data.success) {
        window.sessionStorage.setItem(
          "recommendBannerByDomainSession",
          JSON.stringify(data)
        );
        yield put({
          type: actionTypes.RECOMMEND_BANNER_BY_DOMAIN_SUCCESS,
          payload: data.data,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.RECOMMEND_BANNER_BY_DOMAIN_FAILURE,
        payload: stringifyErrorMessage(error),
      });
    }
  }
}

export function* watchGetCouponsById() {
  yield takeLatest(actionTypes.GET_COUPON_BY_ID, getCouponByIdSaga);
}
export function* watchGetCouponsByWebsite() {
  yield takeLatest(
    actionTypes.GET_COUPON_LIST_BY_WEBSITE,
    getCouponByWebsiteSaga
  );
}
export function* watchGetActiveStoreBaseOnDomain() {
  yield takeLatest(
    actionTypes.GET_ACTIVE_STORES_BASED_ON_DOMAIN,
    getActiveStoresBasedOnDomainSaga
  );
}

export function* watchGetCouponByName() {
  yield takeLatest(actionTypes.GET_COUPON_BY_NAME, getCouponByNameSaga);
}

export function* watchGetEndUSer() {
  yield takeLatest(actionTypes.GET_END_USER, getEndUserSaga);
}

export function* watchGetRecommendBanner() {
  yield takeLatest(
    actionTypes.RECOMMEND_BANNER_BY_DOMAIN,
    getRecommendBannerSaga
  );
}

function* CouponByIdsSaga() {
  yield all([
    fork(watchGetCouponsById),
    fork(watchGetCouponsByWebsite),
    fork(watchGetActiveStoreBaseOnDomain),
    fork(watchGetCouponByName),
    fork(watchGetEndUSer),
    fork(watchGetRecommendBanner),
  ]);
}
export default CouponByIdsSaga;
