import React, { useState } from 'react';
import{Row, Col} from 'reactstrap';
import { postNewsletteraction, resetNewsletterStateAction } from "../../../../Store/NewsLetter/action";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getImageUrl } from "../../../../Utils";

export default function Footr() {
 const [email_id, setEmail] = useState("");

  const validate = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

  const handleChange = (e) => {
    const { name, value } = e.target
    setEmail(value)
  }
  const { successMessage, loading, sucess, errorMessage } = useSelector(
    (state) => state.Newsletter
  );

  const { domainData } = useSelector((state) => state.Website);

  const dispatch = useDispatch();

  const handleNewsletter = () => {
    if(!(validate(email_id))){
    toast.error("Please provide a valid email address.")
    return;
  }
    dispatch(postNewsletteraction({ email_id }));
  };

  React.useEffect(()=>{                                 
   if(sucess){
      toast.success(successMessage);
    dispatch(resetNewsletterStateAction());
    }

    if(errorMessage){
      toast.error(errorMessage);
    dispatch(resetNewsletterStateAction());
    }

  },[dispatch, errorMessage, loading, successMessage, sucess])

  return (
    <div>
      <div id="contact" style={{background: 
      `linear-gradient(to right, 
                       #59063d
                      0%, #59063d 100%)`, color:"#ffeaf6"}}>
        <div className="container">
        <Row>
        <Col xl="8" sm="12">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div id="success"></div>
                <button 

            onClick={() => handleNewsletter()}
            type="submit" className="btn btn-custom btn-lg" style={{color:"white"}}>
                  Subscribe
                </button>
              </form>
            </div>
          </div>
          </Col>
          <Col xl="4" sm="12">
          <div className="col contact-info foot-i2-res">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>Address
                </span>
                {domainData?.contact?.address}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span> Phone
                </span>{" "}
                {domainData?.contact?.phone}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span> Email
                </span>{" "}
                {domainData?.contact?.email}
              </p>
            </div>
          </div>
        </Col>
        </Row>
        </div>
        <div style={{textAlign: "center", color: "white", fontWeight:"bold", fontSize: ".8rem", 
         display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", background:"#424232"}}>
        <div> <img
                src={getImageUrl(domainData?.logo)}
                width="50"
                height="40"
                alt="logo"
                loading="lazy"
                style={{marginRight:"5px",}}
              />
          </div>
          <div style={{display:"flex", alignItems:"center"}}>
              {domainData?.footer_about_us} </div>
              </div>
      </div>
      <ToastContainer
        position='top-right'
      />
    </div>
  )
}