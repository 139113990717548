import React from "react";
import DefaultNav from "../../Header/index";
import NavTwo from "./index2/Header";
import NavThree from "./index3/Header";
 import NavFive from "../Home3/Navbar";
 import NavFour from "./index4/Header";
import {useSelector} from "react-redux";
import { useLocation } from "react-router-dom";

export default function HomeChanger() {

  const location = useLocation();

  const { domainData } = useSelector(
    (state) => state.Website
  );

  React.useEffect(()=>{
    window.scrollTo(0, 0)
  }, [location])

  if(window.location.pathname.includes("/mr")){
    return <NavFour />;
  }else{
  switch (domainData?.home_page) {
    case "Home Page One":
      return <NavTwo />;
    case "Home Page Two":
      return <NavThree />
    case "Home Page Three":
      return <NavFour />;
    case "Home Page Four":
      return <NavFour />;

    default:
      return <DefaultNav />;
  }
}
}