export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID";
export const GET_COUPON_BY_ID_SUCCESS = "GET_COUPON_BY_ID_SUCCESS";
export const GET_COUPON_BY_ID_FAILURE = "GET_COUPON_BY_ID_FAILURE";

export const GET_COUPON_LIST_BY_WEBSITE = "GET_COUPON_LIST_BY_WEBSITE";
export const GET_COUPON_LIST_BY_WEBSITE_SUCCESS =
  "GET_COUPON_LIST_BY_WEBSITE_SUCCESS";
export const GET_COUPON_LIST_BY_WEBSITE_FAILURE =
  "GET_COUPON_LIST_BY_WEBSITE_FAILURE";

export const GET_ACTIVE_STORES_BASED_ON_DOMAIN =
  "GET_ACTIVE_STORES_BASED_ON_DOMAIN";
export const GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS =
  "GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS";
export const GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE =
  "GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE";

export const SET_COUPON_BY_ID_LOADING = "SET_COUPON_BY_ID_LOADING";

export const RESET_COUPON_BY_ID = "RESET_COUPON_BY_ID";

export const GET_COUPON_BY_NAME = "GET_COUPON_BY_NAME";
export const GET_COUPON_BY_NAME_SUCCESS = "GET_COUPON_BY_NAME_SUCCESS";
export const GET_COUPON_BY_NAME_FAILURE = "GET_COUPON_BY_NAME_FAILURE";

export const GET_END_USER = "GET_END_USER";
export const GET_END_USER_SUCCESS = "GET_END_USER_SUCCESS";
export const GET_END_USER_FAILURE = "GET_END_USER_FAILURE";

export const RECOMMEND_BANNER_BY_DOMAIN = "RECOMMEND_BANNER_BY_DOMAIN";
export const RECOMMEND_BANNER_BY_DOMAIN_SUCCESS =
  "RECOMMEND_BANNER_BY_DOMAIN_SUCCESS";
export const RECOMMEND_BANNER_BY_DOMAIN_FAILURE =
  "RECOMMEND_BANNER_BY_DOMAIN_FAILURE";
