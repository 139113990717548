import { put, all, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../Config/axiosInstance";

function* getCouponUrlSaga({ payload }) {
  try {
    if (payload.finalLink && payload.finalLink !== "") {
      yield put({ type: actionTypes.SET_COUPON_URL_LOADING });
      yield put({
        type: actionTypes.GET_COUPON_URL_SUCCESS,
        link: payload.finalLink,
        success: true,
      });
    } else {
      yield put({ type: actionTypes.SET_COUPON_URL_LOADING });
      const { data } = yield API.get(
        `/v2/fetch/couponurl`,
        { params: payload },
        {
          headers: {
            "domain-name": "zizoze.com",
          },
        }
      );
      let apiLink = data.data.coupons[0].finalLink;

      console.log("apiLink", apiLink);

      if (apiLink) {
        yield put({
          type: actionTypes.GET_COUPON_URL_SUCCESS,
          link: apiLink,
          success: true,
        });
      } else {
        yield put({
          type: actionTypes.GET_COUPON_URL_FAILURE,
          errorMessage: "API did not provide a valid link.",
        });
      }
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPON_URL_FAILURE,
      errorMessage: "Cannot get Coupon URL",
    });
  }
}

function* CouponUrlSaga() {
  yield all([yield takeLatest(actionTypes.GET_COUPON_URL, getCouponUrlSaga)]);
}

export default CouponUrlSaga;
