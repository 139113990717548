export const GET_STORES_BASED_ON_DOMAIN = "GET_STORES_BASED_ON_DOMAIN";
export const GET_STORES_BASED_ON_DOMAIN_SUCCESS =
  "GET_STORES_BASED_ON_DOMAIN_SUCCESS";
export const GET_STORES_BASED_ON_DOMAIN_FAILURE =
  "GET_STORES_BASED_ON_DOMAIN_FAILURE";

export const GET_NEWLY_ADDED_STORES = "GET_NEWLY_ADDED_STORES";
export const GET_NEWLY_ADDED_STORES_SUCCESS = "GET_NEWLY_ADDED_STORES_SUCCESS";
export const GET_NEWLY_ADDED_STORES_FAILURE = "GET_NEWLY_ADDED_STORES_FAILURE";

export const GET_TOP_VISITED_STORES = "GET_TOP_VISITED_STORES";
export const GET_TOP_VISITED_STORES_SUCCESS = "GET_TOP_VISITED_STORES_SUCCESS";
export const GET_TOP_VISITED_STORES_FAILURE = "GET_TOP_VISITED_STORES_FAILURE";

export const GET_STORE_BY_ID = "GET_STORE_BY_ID";
export const GET_STORE_BY_ID_SUCCESS = "GET_STORE_BY_ID_SUCCESS";
export const GET_STORE_BY_ID_FAILURE = "GET_STORE_BY_ID_FAILURE";

export const GET_COUPONS_BY_STORE_ID = "GET_COUPONS_BY_STORE_ID";
export const GET_COUPONS_BY_STORE_ID_SUCCESS =
  "GET_COUPONS_BY_STORE_ID_SUCCESS";
export const GET_COUPONS_BY_STORE_ID_FAILURE =
  "GET_COUPONS_BY_STORE_ID_FAILURE";

export const GET_STORES_BY_NAME = "GET_COUPONS_BY_STORE_ID";
export const GET_STORES_BY_NAME_SUCCESS = "GET_STORES_BY_NAME_SUCCESS";
export const GET_STORES_BY_NAME_FAILURE = "GET_STORES_BY_NAME_FAILURE";

export const RESET_STORES_BASED_ON_DOMAIN_STATUS =
  "RESET_STORES_BASED_ON_DOMAIN_STATUS";

export const SET_STORES_BASED_ON_DOMAIN_LOADING =
  "SET_STORES_BASED_ON_DOMAIN_LOADING";
