import DefaultFooter from "../../Footer/index";
import FooterTwo from "./index2/Footer";
import FooterThree from "./index3/Footer";
import FooterFive from "../Home3/Footer";
import FooterFour from "./index4/Footer";
import {useSelector} from "react-redux";

export default function HomeChanger() {
  const { domainData } = useSelector(
    (state) => state.Website
  );

  if(window.location.pathname.includes("mr")){
    return <FooterFour />;
  }else{
    switch (domainData?.home_page) {
    case "Home Page One":
      return <FooterTwo />;
    case "Home Page Two":
      return <FooterThree />;
    case "Home Page Three":
      return <FooterFour />;
    case "Home Page Four":
      return <FooterFour />;
    default:
      return <DefaultFooter />;
  }
  }
}