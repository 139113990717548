import {useSelector, useDispatch} from "react-redux";
import React, { useState,  } from "react";
import { getImageUrl } from "../../../../Utils";
import Loader from "../../../Ui/Loader";
import { Link, } from "react-router-dom";
import { getCouponByNameAction } from "../../../../Store/Coupons/actions";
import { getStoresByNameAction } from "../../../../Store/Stores/actions";

function NavScrollExample() {

  const dispatch = useDispatch();

    const {
    domainData,
    loading: loadingWebsiteData,
  } = useSelector((state) => state.Website);

const [isOpen, setIsOpen] = useState(false);
 const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (searchValue) {
      dispatch(getStoresByNameAction({ name: searchValue, perPage: 1000 }));

      dispatch(getCouponByNameAction({ name: searchValue }));
    }

    enterSearch.current.click();
  };

  const enterSearch = React.useRef();


  return (
    <>
    <div style={{width:"100vw"}}>
     <div id="nav-strip" style={{width:"100vw", backgroundColor:"#005e36", height:"19px" ,fontWeight:"500", fontSize:'.72rem', color:"#aaf4c8"}}>
    <span style={{float: "left", color:"white", marginLeft:"5%", cursor:'pointer'}}>
    <i className="fa fa-flash" style={{color:"white", paddingRight:"3px"}}></i>
    #Amazon Lightening Deal
    </span>
    <span style={{float: "left", color:"white", marginLeft:"2%", cursor:'pointer'}}>
    <i className="fa fa-square" style={{color:"yellow", paddingRight:"3px"}}></i>
    #Advertise with us
    </span>
    <span style={{float: "left", color:"white", marginLeft:"2%", cursor:'pointer'}}>
    <i className="fa fa-money" style={{color:"yellow", paddingRight:"3px"}}></i>
    #100% Cashback
    </span>
    <span style={{float: "right", color:"white", marginRight:"10%", cursor:'pointer'}}>
    <i className="fa fa-money" style={{color:"yellow", paddingRight:"3px"}}></i>
    # Up to 80% off on Electronics
    </span>
    <span style={{float: "right", color:"white", marginRight:"2%", cursor:'pointer'}}>
    <i className="fa fa-money" style={{color:"yellow", paddingRight:"3px"}}></i>
    # Up to 80% off on Electronics
    </span>
    </div>
    <nav className="navbar navbar-expand-lg" style={{backgroundColor: "#028950"}}>
  <a className="navbar-brand" style={{paddingLeft:"3%"}}>
    {loadingWebsiteData ? (
              <Loader
                customStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <Link to="/">
                <img
                  src={getImageUrl(domainData?.logo, "logo512.png")}
                  width="60"
                  height="30"
                  className="d-inline-block align-top"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            )}
  </a>
  <button
    className="navbar-toggler navbar-light"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    onClick={()=> setIsOpen(!isOpen)}
  >
    <span className="navbar-toggler-icon" />
  </button>
  <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="nav-collapse-i3">
    <form id="nav-form-i3" className="form-inline my-2 my-lg-0" style={{display:"flex", flexDirection: "row"}}>
      <input
        id="nav-input-i3"
        className="form-control mr-sm-2 shadow-none"
        type="search"
        placeholder="What are you looking for?"
        aria-label="Search"
        style={{outline:"none", borderRadius:"3px 0 0 3px", width: "25vw", height:"32px", color:"#028950"}}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              value={searchValue}
      />

            <Link to="/searchpage" style={{
                    padding: "0",}}>
      <button id="search-i3" type="submit" style={{color:"#c9f8de", 
       height:"31px", 
       backgroundColor: "#005e36",
       border:"1px solid #005e36",
       borderRadius:"0 2px 2px 0"}}
        onClick={handleSearch}
         ref={enterSearch}
                  >
        Search
      </button>
      </Link>
    </form>
    <ul id="nav-ul-i3" className="navbar-nav mr-auto">
      <li className="nav-item active" style={{marginRight:"3vw", cursor:"pointer"}}>
                <Link
                  to="/"
                  style={{ textDecoration: "none", 
                    color:"white" ,}}
                >
                  Home
                </Link>
      </li>
      <li className="nav-item"   style={{marginRight:"3vw", cursor:"pointer"}}>
                <Link
                  to="/stores"
                  style={{ textDecoration: "none", 
                    color:"white" }}
                >
                  Stores
                </Link>
      </li>
      <li className="nav-item dropdown"  style={{marginRight:"3vw", cursor:"pointer"}}>
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", 
                    color:"white" }}
                  >
                    {" "}
                    Categories
                  </Link>
      </li>
      <li className="nav-item"  style={{marginRight:"3vw", cursor:"pointer"}}>
                <Link
                  to="/aboutus"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0,0,0,.55)",
                    whiteSpace: "nowrap",
                    color:"white"
                  }}
                >
                  {" "}
                  About Us
                </Link>
      </li>
      <li  style={{marginRight:"3vw", cursor:"pointer"}}>
                <Link
                  to="/contactus"
                  style={{
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    color:"white"
                  }}
                >
                  {" "}
                  Contact Us
                </Link>
                </li>
    </ul>
  </div>
</nav>
</div>
</>

  );
}

export default NavScrollExample;