import { fork, put, all, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../Config/axiosInstance";
import { stringifyErrorMessage } from "../../Utils";

function* getWebsiteDataSaga() {
  try {
    yield put({ type: actionTypes.SET_WEBSITE_LOADING });
    const { data } = yield API.get(
      "/websiteData/fetchAll"

      // , {
      //   headers: {
      //     "domain-name": "www.bisharafak.com",
      //   },
      // }
    );
    yield put({
      type: actionTypes.GET_WEBSITE_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_WEBSITE_DATA_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

function* featureCardsSaga() {
  try {
    yield put({ type: actionTypes.SET_WEBSITE_LOADING });
    const { data } = yield API.get("/end-user/deals-info/");
    if (data.sucess) {
      yield put({
        type: actionTypes.GET_FEATURE_CARDS_SUCCESS,
        payload: data.data,
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_FEATURE_CARDS_FAILURE,
      payload: stringifyErrorMessage(err),
    });
  }
}

function* getAdBannerByDomainSaga() {
  const endUserDataSession = JSON.parse(
    window.sessionStorage.getItem("endUserDataSession")
  );
  if (endUserDataSession) {
    yield put({
      type: actionTypes.GET_AD_BANNER_BY_DOMAIN_SUCCESS,
      payload: endUserDataSession,
    });
  } else {
    try {
      yield put({ type: actionTypes.SET_WEBSITE_LOADING });
      const { data } = yield API.get(
        "/end-user/advertisement/getAdBannerByDomain"
      );
      if (data.data) {
        window.sessionStorage.setItem(
          "endUserDataSession",
          JSON.stringify(data.data)
        );
        yield put({
          type: actionTypes.GET_AD_BANNER_BY_DOMAIN_SUCCESS,
          payload: data.data,
        });
      }
    } catch (error) {
      yield put({
        type: actionTypes.GET_AD_BANNER_BY_DOMAIN_FAILURE,
        payload: stringifyErrorMessage(error),
      });
    }
  }
}

function* getWebsiteDataByDomainSaga({
  setDomainDataSession,
  domainDataSession,
}) {
  if (domainDataSession?.header_color) {
    yield put({
      type: actionTypes.GET_WEBSITE_DATA_BY_DOMAIN_SUCCESS,
      payload: domainDataSession,
    });
  } else {
    try {
      yield put({ type: actionTypes.SET_WEBSITE_LOADING });
      const { data } = yield API.get(
        "/api/end-user/website-data/fetchByDomain"
      );
      if (data.data) {
        setDomainDataSession(data.data);
      }
      yield put({
        type: actionTypes.GET_WEBSITE_DATA_BY_DOMAIN_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      yield put({
        type: actionTypes.GET_WEBSITE_DATA_BY_DOMAIN_FAILURE,
        payload: stringifyErrorMessage(error),
      });
    }
  }
}

function* getGoogleVerificationCodeByDomainSaga() {
  try {
    yield put({ type: actionTypes.SET_GOOGLE_VERIFICATION_CODE_LOADING });
    const { data } = yield API.get(
      "/api/end-user/website-data/google-verification-tag"
    );
    yield put({
      type: actionTypes.GET_GOOGLE_VERIFICATION_CODE_DATA_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_GOOGLE_VERIFICATION_CODE_DATA_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

export function* watchGetWebsiteData() {
  yield takeLatest(actionTypes.GET_WEBSITE_DATA, getWebsiteDataSaga);
}
export function* watchGetGoogleVerificationCodeData() {
  yield takeLatest(
    actionTypes.GET_GOOGLE_VERIFICATION_CODE_DATA,
    getGoogleVerificationCodeByDomainSaga
  );
}

export function* watchGetWebsiteDataByDomainSaga() {
  yield takeLatest(
    actionTypes.GET_WEBSITE_DATA_BY_DOMAIN,
    getWebsiteDataByDomainSaga
  );
}

export function* watchGetAdBannerByDomainSaga() {
  yield takeLatest(
    actionTypes.GET_WEBSITE_DATA_BY_DOMAIN,
    getAdBannerByDomainSaga
  );
}

export function* watchFeatureCardsSaga() {
  yield takeLatest(actionTypes.GET_FEATURE_CARDS, featureCardsSaga);
}

function* WebsiteSaga() {
  yield all([
    fork(watchGetWebsiteData),
    fork(watchGetWebsiteDataByDomainSaga),
    fork(watchGetAdBannerByDomainSaga),
    fork(watchGetGoogleVerificationCodeData),
    fork(watchFeatureCardsSaga),
  ]);
}

export default WebsiteSaga;
