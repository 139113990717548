import {useSelector, useDispatch} from "react-redux";
import React, { useEffect, useState,  } from "react";
import { getImageUrl } from "../../../../Utils";
import Loader from "../../../Ui/Loader";
import { Link, } from "react-router-dom";
import {
  getAllCategoryAction,
  getWebsiteDataByDomainAction,
  getGoogleVerificationCodeByDomainAction
} from "../../../../Store/actions";
import { getCouponByNameAction } from "../../../../Store/Coupons/actions";
import { getStoresByNameAction } from "../../../../Store/Stores/actions";

function NavScrollExample() {

  const dispatch = useDispatch();

    const {
    domainData,
    loading: loadingWebsiteData,
  } = useSelector((state) => state.Website);

const [isOpen, setIsOpen] = useState(false);
 const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (searchValue) {
      dispatch(getStoresByNameAction({ name: searchValue, perPage: 1000 }));

      dispatch(getCouponByNameAction({ name: searchValue }));
    }

    enterSearch.current.click();
  };

  const enterSearch = React.useRef();

  return (
    <div style={{background:'#424242'}}> <div className="container">
    <nav className="navbar navbar-expand-lg" style={{backgroundColor: "#424242"}}>
  <a className="navbar-brand">
    {loadingWebsiteData ? (
              <Loader
                customStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ) : (
              <Link to="/">
                <img
                  src={getImageUrl(domainData?.logo, "logo512.png")}
                  width="60"
                  height="30"
                  className="d-inline-block align-top"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            )}
  </a>
  <button
    className="navbar-toggler navbar-light"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    onClick={()=> setIsOpen(!isOpen)}
  >
    <span className="navbar-toggler-icon" />
  </button>
  <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`} id="nav-collapse-i3">
    <form id="nav-form-i4" className="form-inline my-2 my-lg-0" style={{display:"flex", flexDirection: "row", margin:"0 60px"}}>
      <input
        className="form-control mr-sm-2 shadow-none"
        type="search"
        placeholder="What are you looking for?"
        aria-label="Search"
        style={{outline:"none", borderRadius:"50px 0 0 50px", width: "300px", height:"32px", color:"#028950"}}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              value={searchValue}
              id="nav-input-i4"
      />

            <Link to="/searchpage" style={{ padding: "0",}}> <button
            id="search-i3" type="submit" style={
            {color:"#f2f2f2", height:"32px", backgroundColor: "#212121",
            border:"1px solid #212121", borderRadius:"0 50px 50px 0", paddingRight:"10px"}} onClick=
            {handleSearch} ref={enterSearch}
                  >
        Search
      </button>
      </Link>
    </form>
    <ul id="nav-ul-i3" className="navbar-nav mr-auto" style={{fontWeight:"600"}}>
      <li className="nav-item active" style={{marginRight:"9%", cursor:"pointer"}}>
                <Link
                  to="/"
                  style={{ textDecoration: "none", 
                    color:"white" ,}}
                >
                  Home
                </Link>
      </li>
      <li className="nav-item"   style={{marginRight:"9%", cursor:"pointer"}}>
                <Link
                  to="/stores"
                  style={{ textDecoration: "none", 
                    color:"white" }}
                >
                  Stores
                </Link>
      </li>
      <li className="nav-item dropdown"  style={{marginRight:"9%", cursor:"pointer"}}>
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", 
                    color:"white" }}
                  >
                    {" "}
                    Categories
                  </Link>
      </li>
      <li className="nav-item"  style={{marginRight:"9%", cursor:"pointer"}}>
                <Link
                  to="/aboutus"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0,0,0,.55)",
                    whiteSpace: "nowrap",
                    color:"white"
                  }}
                >
                  {" "}
                  About Us
                </Link>
      </li>
      <li  style={{marginRight:"30px", cursor:"pointer"}}>
                <Link
                  to="/contactus"
                  style={{
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                    color:"white"
                  }}
                >
                  {" "}
                  Contact Us
                </Link>
                </li>
    </ul>
  </div>
</nav>
</div></div>
  );
}

export default NavScrollExample;