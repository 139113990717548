import { fork, put, all, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import API from "../../Config/axiosInstance";
import { stringifyErrorMessage } from "../../Utils";

function* getStoresBasedOnDomainSaga() {
  try {
    yield put({ type: actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get(
      "/api/end-user/store/getStoresBasedOnDomain"
    );
    yield put({
      type: actionTypes.GET_STORES_BASED_ON_DOMAIN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_STORES_BASED_ON_DOMAIN_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getNewlyAddedStoreSaga() {
  try {
    yield put({ type: actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get("/api/end-user/store/latest-stores");
    yield put({
      type: actionTypes.GET_NEWLY_ADDED_STORES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_NEWLY_ADDED_STORES_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getTopVisitedStoreSaga() {
  try {
    yield put({ type: actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get("/api/end-user/store/top-visited-stores");
    yield put({
      type: actionTypes.GET_TOP_VISITED_STORES_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_TOP_VISITED_STORES_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

function* getStoreByIdSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get(
      "/api/end-user/store/getStoreByIdAndAllItsCoupons",
      {
        // headers: {
        //   "domain-name": "www.bisharafak.com",
        // },
        params: {
          storeId: payload,
        },
      }
    );
    yield put({
      type: actionTypes.GET_STORE_BY_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_STORE_BY_ID_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getStoresByNameSaga({ payload }) {
  payload = {...payload, resolution: `${window.innerWidth}x${window.innerHeight}`}
  try {
    // yield put({ type: actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get("/end-user/store/search-store", {
      params: payload,
    });

    yield put({
      type: actionTypes.GET_STORES_BY_NAME_SUCCESS,
      payload: data.data,
      StoreTotalRecords: data.totalRecords,
      StoreTotal_pages: data.total_pages,
      StorePer_page: data.per_page,
      page: data.page,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_STORES_BY_NAME_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
function* getCouponsStoreByIdSaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get("/end-user/coupon/coupons-by-storeid", {
      params: payload,
    });
    yield put({
      type: actionTypes.GET_COUPONS_BY_STORE_ID_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPONS_BY_STORE_ID_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}
export function* watchGetStoresBasedOnDomain() {
  yield takeLatest(
    actionTypes.GET_STORES_BASED_ON_DOMAIN,
    getStoresBasedOnDomainSaga
  );
}
export function* watchTopVisitedStore() {
  yield takeLatest(actionTypes.GET_TOP_VISITED_STORES, getTopVisitedStoreSaga);
}

export function* watchGetStoreById() {
  yield takeLatest(actionTypes.GET_STORE_BY_ID, getStoreByIdSaga);
}
export function* watchGetCouponsStoreById() {
  yield takeLatest(
    actionTypes.GET_COUPONS_BY_STORE_ID,
    getCouponsStoreByIdSaga
  );
}
export function* watchgetStoresByName() {
  yield takeLatest(actionTypes.GET_STORES_BY_NAME, getStoresByNameSaga);
}

function* StoresSaga() {
  yield all([
    fork(watchGetStoresBasedOnDomain),
    fork(getNewlyAddedStoreSaga),
    fork(getTopVisitedStoreSaga),
    fork(watchGetStoreById),
    fork(watchGetCouponsStoreById),
    fork(watchgetStoresByName),
  ]);
}

export default StoresSaga;
