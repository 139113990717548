import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../Assets/Scss/Footer.scss";
import { FaUserAlt, FaDollarSign, FaShoppingBag, FaTags } from "react-icons/fa";
import { FaHome, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { getImageUrl } from "../../Utils";
import { Link } from "react-router-dom";
import { getFeatureCardsAction } from "../../Store/actions";

function Index() {
  const dispatch = useDispatch();
  const { domainData, featureCards } = useSelector((state) => state.Website);
  const { categoryList } = useSelector((state) => state.Category);

  useEffect(() => {
    if (!featureCards) {
      dispatch(getFeatureCardsAction());
    }
  }, []);

  return (
    <footer>
      <Container className="mt-3">
        <Row style={{ padding: "1rem" }}>
          {featureCards.map((card) => (
            <Col md={3} sm={12}>
              <div className="d-flex align-items-center mt-2 mb-2">
                <img
                  src={getImageUrl(card?.icon)}
                  alt=""
                  width="100"
                  height="80"
                  style={{ objectFit: "contain" }}
                />
                <p>
                  <b> {card?.title}</b> <br /> {card?.description}
                </p>
              </div>
            </Col>
          ))}
          {/* <Col md={3} sm={12}>
            <div className="d-flex align-items-center mt-2 mb-2">
              <FaTags
                style={{
                  fontSize: "3rem",
                  color: "orange",
                  marginRight: "1rem",
                }}
              />
              <p>
                <b> 83681</b> <br /> Promo codes and deals available at {domainData?.name}
              </p>
            </div>
          </Col>

          <Col md={3} sm={12}>
            <div className="d-flex align-items-center  mt-2 mb-2">
              <FaShoppingBag
                style={{
                  fontSize: "3rem",
                  color: "orange",
                  marginRight: "1rem",
                }}
              />
              <p>
                <b>12340</b> <br /> stores offering a cheaper shopping
              </p>
            </div>
          </Col>
          <Col md={3} sm={12}>
            <div className="d-flex align-items-center  mt-2 mb-2">
              <FaUserAlt
                style={{
                  fontSize: "3rem",
                  color: "orange",
                  marginRight: "1rem",
                }}
              />
              <p>
                <b>4200858</b> <br />
                users save with {domainData?.name} every month
              </p>
            </div>
          </Col>
          <Col md={3} sm={12}>
            <div className="d-flex align-items-center  mt-2 mb-2">
              <FaDollarSign
                style={{
                  fontSize: "3rem",
                  color: "orange",
                  marginRight: "1rem",
                }}
              />
              <p>
                <b>38%</b> <br />
                average discount value brought to you by {domainData?.name}
              </p>
            </div>
          </Col> */}
        </Row>
      </Container>
      <div
        className="footer"
        style={{
          backgroundColor: domainData ? domainData.header_color : "#fff",
        }}
      >
        <Container className="mt-3">
          <Row>
            <Col md={3} lg={3} xl={3} className="mx-auto mt-2">
              <img
                src={getImageUrl(domainData?.logo, "logo512.png")}
                width="100"
                height="80"
                className="d-inline-block align-top"
                alt="logo"
                loading="lazy"
              />
              {/* <h6 className="text-uppercase mb-4 font-weight-bold">Casanova</h6> */}
              <p>{domainData?.footer_about_us}</p>
              <p>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </p>
            </Col>
            <Col md={3} lg={3} xl={3} className="mx-auto mt-2">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                Categories
              </h6>
              {categoryList?.slice(0, 5)?.map((category, key) => (
                <p key={key}>
                  <Link to={`/category/${category.category}`}>
                    {category.category}
                  </Link>
                </p>
              ))}
            </Col>
            <Col md={3} lg={3} xl={3} className="mx-auto mt-2">
              <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
              <p>
                <FaHome /> {domainData?.contact?.address}
              </p>
              <p>
                <FaEnvelope /> {domainData?.contact?.email}
              </p>
              <p>
                <FaPhoneAlt /> {domainData?.contact?.phone}
              </p>
            </Col>
          </Row>
          <Row></Row>
        </Container>
      </div>
    </footer>
  );
}

export default Index;
