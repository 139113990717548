import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaHome,
  FaEnvelope,
  FaPhoneAlt,
  FaBars,
  FaAddressBook,
} from "react-icons/fa";
import { FaArrowUp, FaLink, FaStore } from "react-icons/fa";
import { getImageUrl } from "../../../../Utils";

export default function Footr() {
  const history = useHistory();

  const { storesList } = useSelector((state) => state.Stores);
  const { categoryList } = useSelector((state) => state.Category);

  const { domainData } = useSelector((state) => state.Website);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <>
      {/* Footer */}
      <footer
        className=" text-center text-white"
        style={{ backgroundColor: "#424242", color: "#c9f8de" }}
      >
        {/* Grid container */}
        <div className="container p-4">
          {/*Grid row*/}
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5
                className="text-uppercase"
                style={{ fontSize: "1.2rem", paddingBottom: "4px", 
                display: "flex",
                justifyContent: "center",
                alignItems:"center",
              gap: "5px" }}
              >
                <FaAddressBook /> Contact
              </h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <p>
                    <FaHome /> {domainData?.contact?.address}
                  </p>
                </li>
                <li>
                  <p>
                    <FaEnvelope /> {domainData?.contact?.email}
                  </p>
                </li>
                <li>
                  <p>
                    <FaPhoneAlt /> {domainData?.contact?.phone}
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5
                className="text-uppercase"
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  justifyContent: "left",
                  alignItems:"center",
                  gap: "5px"
                }}
              >
                <FaBars />Categories
              </h5>
              <ul
                className="list-unstyled mb-0"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {categoryList?.slice(0, 11).map((category, key) => (
                  <li>
                    <div className="tag-i4">
                      <div style={{ margin: "0", padding: "0" }}>
                        <span
                          style={{cursor:"pointer"}}
                          component={Link}
                          to={`/category/${category.category}`}
                          onClick={()=>{
                            history.push(`/category/${category.category}`)
                          }}
                        >
                          {category?.category?.slice(0, 12)}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
              <h5
                className="text-uppercase"
                style={{
                  fontSize: "1.2rem",
                  display: "flex",
                  justifyContent: "left",
                  alignItems:"center",
                  gap: "5px"
                }}
              >
                <FaStore />
                Stores
              </h5>
              <ul
                className="list-unstyled mb-0"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {storesList?.slice(0, 11).map((category, key) => (
                  <li>
                    <div className="tag-i4">
                      <div style={{ margin: "0", padding: "0" }}>
                        <span
                          style={{cursor:"pointer"}}
                          component={Link}
                          to={`/store/${category.slug}`}
                          onClick={()=>{
                            history.push(`/store/${category.slug}`)
                          }}
                        >
                          {category?.slug?.slice(0, 12)}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/*Grid column*/}
            {/*Grid column*/}
            <div className="col-lg-3 col-md-6 mb-md-0">
              <h5 className="text-uppercase" style={{ fontSize: "1.2rem", 
                  display: "flex",
                  justifyContent: "center",
                  alignItems:"center",
                  gap: "5px" }}>
                <FaLink />
                Navigate
              </h5>
              <ul className="list-unstyled mb-0">
                <li style={{ paddingBottom: "1.2%" }}>
                  <Link
                    to="/stores"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Stores
                  </Link>
                </li>
                <li style={{ paddingBottom: "1.2%" }}>
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    Categories
                  </Link>
                </li>
                <li style={{ paddingBottom: "1.2%" }}>
                  <Link
                    to="/aboutus"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    About
                  </Link>
                </li>
                <li style={{ paddingBottom: "1.2%" }}>
                  <Link
                    to="/contactus"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {" "}
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            {/*Grid column*/}
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
            fontSize: ".8rem",
            padding: "5px 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            background: "#2e2e2e",
          }}
        >
          <div>
            {" "}
            <img
              src={getImageUrl(domainData?.logo)}
              width="50"
              height="40"
              alt="logo"
              loading="lazy"
              style={{ marginRight: "5px" }}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {domainData?.footer_about_us}{" "}
          </div>
        </div>
      </footer>
      {/* Footer */}
      <ToastContainer position="top-right" />

      <button
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1.2rem",
          border: "2px solid #4B5563",
          color: "#111827",
          borderRadius: "5px",
          background: "#4B5563",
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <FaArrowUp />
      </button>
    </>
  );
}
