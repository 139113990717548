import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  errorMessage: "",
  success: false,
  successMessage: "",
  loading: false,
  sucess: false,
};

const Newsletter = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POST_NEWSLETTER_ACTION_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case actionTypes.POST_NEWSLETTER_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        successMessage: action.successMessage,
        sucess: action.sucess,
      };
    case actionTypes.POST_NEWSLETTER_ACTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.errorMessage,
        sucess: false,
      };
    case actionTypes.RESET_NEWSLETTER_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        sucess: false,
        successMessage:"",
      };

    default:
      return { ...state };
  }
};

export default Newsletter;
