export const GET_WEBSITE_DATA = "GET_WEBSITE_DATA";
export const GET_WEBSITE_DATA_SUCCESS = "GET_WEBSITE_DATA_SUCCESS";
export const GET_WEBSITE_DATA_FAILURE = "GET_WEBSITE_DATA_FAILURE";

export const GET_GOOGLE_VERIFICATION_CODE_DATA = "GET_GOOGLE_VERIFICATION_CODE_DATA";
export const GET_GOOGLE_VERIFICATION_CODE_DATA_SUCCESS = "GET_GOOGLE_VERIFICATION_CODE_DATA_SUCCESS";
export const GET_GOOGLE_VERIFICATION_CODE_DATA_FAILURE = "GET_GOOGLE_VERIFICATION_CODE_DATA_FAILURE";

export const GET_WEBSITE_DATA_BY_DOMAIN = "GET_WEBSITE_DATA_BY_DOMAIN";
export const GET_WEBSITE_DATA_BY_DOMAIN_SUCCESS = "GET_WEBSITE_DATA_BY_DOMAIN_SUCCESS";
export const GET_WEBSITE_DATA_BY_DOMAIN_FAILURE = "GET_WEBSITE_DATA_BY_DOMAIN_FAILURE";

export const GET_FEATURE_CARDS = "GET_FEATURE_CARDS";
export const GET_FEATURE_CARDS_SUCCESS = "GET_FEATURE_CARDS_FAILURE";
export const GET_FEATURE_CARDS_FAILURE = "GET_FEATURE_CARDS_FAILURE";

export const RESET_WEBSITE_SUCCESS_STATUS = "RESET_WEBSITE_SUCCESS_STATUS";

export const SET_WEBSITE_LOADING = "SET_WEBSITE_LOADING";
export const SET_GOOGLE_VERIFICATION_CODE_LOADING = "SET_GOOGLE_VERIFICATION_CODE_LOADING";

export const GET_AD_BANNER_BY_DOMAIN = "GET_AD_BANNER_BY_DOMAIN";
export const GET_AD_BANNER_BY_DOMAIN_SUCCESS = "GET_AD_BANNER_BY_DOMAIN_SUCCESS";
export const GET_AD_BANNER_BY_DOMAIN_FAILURE = "GET_AD_BANNER_BY_DOMAIN_FAILURE";
