import * as actionTypes from "./actionTypes";

const initialState = {
  storesList: [],
  newlyAddedStores: [],
  topRatedStores: [],
  selectedStore: null,
  StoreCouponList: [],
  totalRecords: 0,
  totalPages: 0,
  error: null,
  errorMessage: "",
  success: false,
  successMessage: "",
  loading: false,
  searchedStores: [],
  StoreTotalRecords: 0,
  StoreTotal_pages: 0,
  StorePer_page: 0,
  StorePage: 0,
};

const Stores = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STORES_BASED_ON_DOMAIN_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: "",
      };

    case actionTypes.GET_STORES_BASED_ON_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        storesList: action.payload,
      };
    case actionTypes.GET_STORES_BASED_ON_DOMAIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        storesList: [],
      };

    case actionTypes.GET_NEWLY_ADDED_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        newlyAddedStores: action.payload,
      };
    case actionTypes.GET_NEWLY_ADDED_STORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        newlyAddedStores: [],
      };

    case actionTypes.GET_TOP_VISITED_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        topRatedStores: action.payload,
      };
    case actionTypes.GET_TOP_VISITED_STORES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        topRatedStores: [],
      };

    case actionTypes.GET_STORE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedStore: action.payload,
      };
    case actionTypes.GET_STORE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        selectedStore: null,
      };
    case actionTypes.GET_STORES_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        searchedStores: action.payload,
        StoreTotalRecords: action.StoreTotalRecords,
        StoreTotal_pages: action.StoreTotal_pages,
        StorePer_page: action.StorePer_page,
        StorePage: action.StorePage,
      };
    case actionTypes.GET_STORES_BY_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        searchedStores: null,
      };
    case actionTypes.GET_COUPONS_BY_STORE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedStore: action.payload,
        totalRecords: action.payload.totalRecords,
        totalPages: action.payload.total_pages,
      };
    case actionTypes.GET_COUPONS_BY_STORE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        selectedStore: [],
      };

    case actionTypes.RESET_STORES_BASED_ON_DOMAIN_STATUS:
      return {
        ...state,
        loading: false,
        error: false,
        errorMessage: "",
        successMessage: "",
        success: false,
      };
    default:
      return { ...state };
  }
};

export default Stores;
