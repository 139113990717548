import React from "react";
import { Spinner } from "react-bootstrap";

function Loader({ animation, variant, customStyle }) {
  return (
    <div style={customStyle}>
      <Spinner
        animation={animation ? animation : "border"}
        variant={variant ? variant : "primary"}
      />
    </div>
  );
}

export default Loader;
