import * as actionTypes from "./actionTypes";

export const getCouponUrlAction = (payload) => ({
  type: actionTypes.GET_COUPON_URL,
  payload,
});

export const resetCouponUrl = () => ({
  type: actionTypes.RESET_COUPON_URL,
});
