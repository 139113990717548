import { combineReducers } from "redux";
import Category from "./Category/reducer";
import CouponBasedOnDomain from "./CouponBaseOnDomain/reducer";
import Stores from "./Stores/reducer";
import Website from "./Website/reducer";
import Contact from "./Contact/reducer";
import Coupon from "./Coupons/reducer";
import CouponUrl from "./couponUrl/reducer";
import Newsletter from "./NewsLetter/reducers";

const rootReducer = combineReducers({
  Category,
  CouponBasedOnDomain,
  Stores,
  Website,
  Contact,
  Coupon,
  CouponUrl,
  Newsletter,
});

export default rootReducer;
