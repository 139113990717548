import { fork, put, all, takeLatest } from "redux-saga/effects";

import * as actionTypes from "./actionTypes";
import API from "../../Config/axiosInstance";
import { stringifyErrorMessage } from "../../Utils";

function* getCouponsBasedOnDomainSaga() {
  try {
    yield put({ type: actionTypes.SET_COUPON_BASED_ON_DOMAIN_LOADING });
    const { data } = yield API.get("/end-user/coupon/getCouponsBasedOnDomain", {
      // headers: {
      //   "domain-name": "www.bisharafak.com",
      // },
      params : {resolution: `${window.innerWidth}x${window.innerHeight}`}
    });
    yield put({
      type: actionTypes.GET_COUPON_BASED_ON_DOMAIN_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_COUPON_BASED_ON_DOMAIN_FAILURE,
      payload: stringifyErrorMessage(error),
    });
  }
}

export function* watchGetCouponsBasedOnDomain() {
  yield takeLatest(
    actionTypes.GET_COUPON_BASED_ON_DOMAIN,
    getCouponsBasedOnDomainSaga
  );
}

function* CouponBasedOnDomainSaga() {
  yield all([fork(watchGetCouponsBasedOnDomain)]);
}

export default CouponBasedOnDomainSaga;
