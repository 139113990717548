import { BASE_URL } from "../Config/axiosInstance";

export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const stringifyErrorMessage = (error) => {
  const message = error?.response?.data?.message;
  if (message) {
    if (Array.isArray(message)) {
      return message.reduce(
        (acc, err) =>
          acc === "" ? err.errorMessage : `${acc}, ${err.errorMessage}`,
        ""
      );
    }
    if (typeof message === "string") {
      return message;
    }
  }
  if (error?.message) {
    if (typeof error.message === "string") {
      return error.message;
    }
  }
  return error;
};

export const descendingComparator = (a, b, orderBy) => {
  if (orderBy === "date") {
    if (
      new Date(b["createdAt"]).getMilliseconds() <
      new Date(a["createdAt"]).getMilliseconds()
    ) {
      return -1;
    }
    if (
      new Date(b["createdAt"]).getMilliseconds() >
      new Date(a["createdAt"]).getMilliseconds()
    ) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const validateStringField = (isValid, fieldName, nameInMessage) => {
  if (fieldName === "" || (fieldName && `${fieldName}`.trim() === "")) {
    isValid = false;
    return `${nameInMessage} is required`;
  }
  return "";
};

export const getCurrentUserRole = () => sessionStorage.getItem("role");

export const limitStringContent = (input, limit) =>
  input?.length > limit ? `${input.slice(0, limit)}...` : input;

export const getImageUrl = (url, placeholderImage) => {
  if (url && url !== "") {
    return `${BASE_URL}/${url}`;
  }
  return placeholderImage;
};
export const getImageUrlhttps = (url, placeholderImage) => {
  if(url && url !==''){
    if(url.includes('http')) {
     
      return url
    }
    return `${BASE_URL}/${url}`
  }
  return placeholderImage;
}

export const sanitizeString = (string) =>
  typeof string === "string" && string.replace(/(nbsp|amp|quot|lt|gt);/g, "");
