import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import { getCouponByNameAction } from "../../Store/Coupons/actions";
import { getStoresByNameAction } from "../../Store/Stores/actions";
import { useHistory } from "react-router";
import { FaSearch, FaArrowUp } from "react-icons/fa";
import {
  getAllCategoryAction,
  getWebsiteDataByDomainAction,
  getStoresBasedOnDomainAction,
  getGoogleVerificationCodeByDomainAction,
} from "../../Store/actions";
import { getImageUrl } from "../../Utils";
import Loader from "../Ui/Loader";
import { BASE_URL } from "../../Config/axiosInstance";
import "../../Assets/Scss/Header.scss";
import { scriptEncoding } from "../../Utils/helpers";
import useSessionStorage from "../../Hooks/useSessionStorage";

function Index() {
  const dispatch = useDispatch();

  const {
    domainData,
    loading: loadingWebsiteData,
    googleVerificationTag,
    googleVerificationTagLoading,
    footerJS,
  } = useSelector((state) => state.Website);
  const { categoryList } = useSelector((state) => state.Category);
  const [domainDataSession, setDomainDataSession] = useSessionStorage(
    "domainDataSession",
    {}
  );

  // console.log("domainDataSession", domainDataSession)

  const history = useHistory();
  const getdata = (websiteData) => {
    const data = [];

    if (typeof websiteData === "string") {
      const a = websiteData?.split("</script>");
      a.forEach((ele) => {
        if (ele.includes("async")) {
          const da = ele.match(/(((https?:\/\/)|(www\.))[^\s]+)/g);
          if (da) {
            data.push({
              isAsync: true,
              src: da[0]?.slice(0, -2),
              body: null,
            });
          }else{
            const srcValue = ele.match(/src="([^"]*)"/); // Extract src attribute value
            if (srcValue && srcValue[1].startsWith("//")) {
              // If src attribute value starts with '//', it's already a complete URL
              const updatedSrc = "https:" + srcValue[1];
              data.push({
                isAsync: true,
                src: updatedSrc,
                body: null,
              });
            }
          }
        } else {
          data.push({
            body: ele?.slice(8),
          });
        }
      });
    }
    return data;
  };

  useEffect(() => {
    dispatch(
      getWebsiteDataByDomainAction(setDomainDataSession, domainDataSession)
    );
    dispatch(getAllCategoryAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStoresBasedOnDomainAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getGoogleVerificationCodeByDomainAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    if (searchValue) {
      dispatch(getStoresByNameAction({ name: searchValue, perPage: 1000 }));

      dispatch(getCouponByNameAction({ name: searchValue }));
      history.push({
        pathname: "/searchpage",
      });
    }

    enterSearch.current.click();
  };

  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  const enterSearch = React.useRef();

  return (
    <>
      <Helmet>
        {domainDataSession?.header_html &&
          parse(domainDataSession?.header_html)}
        <title>{domainDataSession?.name}</title>
        <link rel="canonical" href="/" />
        <meta name="keywords" content={domainDataSession?.meta_tag} />
        <meta
          name="description"
          content={domainDataSession?.meta_description}
        />

        <link
          rel="icon"
          type="image/png"
          href={`${BASE_URL}/${domainDataSession?.favicon}`}
          sizes="16x16"
        />
        {
          //eslint-disable-next-line array-callback-return
          getdata(domainDataSession?.header_js).map((item) => {
            if (item.isAsync) {
              return <script async src={item.src}></script>;
            }
            if (item.body) {
              return <script>{item.body}</script>;
            }
          })
        }
        {
          // eslint-disable-next-line array-callback-return
          getdata(domainDataSession?.footer_js).map((item) => {
            if (item.isAsync) {
              return (
                <script async src={item.src}>
                  {" "}
                </script>
              );
            }
            if (item.body) {
              return <script>{item.body}</script>;
            }
          })
        }
        {!googleVerificationTagLoading && googleVerificationTag
          ? scriptEncoding(googleVerificationTag)
          : null}
        {!googleVerificationTagLoading && footerJS
          ? scriptEncoding(footerJS)
          : null}
      </Helmet>
      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>
          <Navbar.Brand href="/">
            {loadingWebsiteData ? (
              <span></span>
            ) : (
              // <Loader
              //   customStyle={{
              //     display: "flex",
              //     justifyContent: "center",
              //     alignItems: "center",
              //   }}
              // />
              <Link to="/">
                <img
                  src={getImageUrl(domainDataSession?.logo, "logo512.png")}
                  width="60"
                  height="45"
                  className="d-inline-block align-top"
                  alt="logo"
                  loading="lazy"
                />
              </Link>
            )}
          </Navbar.Brand>
          <div id="searchBar" className="input-group">
            <input
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              value={searchValue}
              type="text"
              className="form-control shadow-none"
              placeholder="Search"
            />
            <div className="input-group-append">
              <button
                ref={enterSearch}
                style={{
                  backgroundColor: domainDataSession
                    ? domainDataSession.header_color
                    : "#fff",
                  borderColor: domainDataSession
                    ? domainDataSession.header_color
                    : "#fff",
                  borderRadius: "0 4px 4px 0",
                }}
                onClick={handleSearch}
                class="btn btn-danger shadow-none"
                type="button"
              >
                {/* <Link to="/searchpage"> */}
                <FaSearch style={{ fontSize: "1.4rem" }} />
                {/* </Link> */}
              </button>
            </div>
          </div>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <Nav.Link>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "rgba(0,0,0,.55)" }}
                >
                  Home
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/stores"
                  style={{ textDecoration: "none", color: "rgba(0,0,0,.55)" }}
                >
                  Stores
                </Link>
              </Nav.Link>
              <NavDropdown
                title="Categories"
                id="collasible-nav-dropdown"
                variant="light"
              >
                {categoryList?.length > 0 &&
                  categoryList?.slice(0, 5).map((category, key) => (
                    <span style={{ backgroundColor: "green", width: "200%" }}>
                      <NavDropdown.Item id="category-dropdown-item">
                        <Link
                          key={key}
                          id="category-dropdown"
                          to={`/category/${category.category}`}
                          style={{}}
                        >
                          {category?.category}
                        </Link>
                      </NavDropdown.Item>
                    </span>
                  ))}
                <NavDropdown.Divider />
                <NavDropdown.Item href="/category">
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", color: "rgba(0,0,0,.55)" }}
                  >
                    {" "}
                    Browse all Categories
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link>
                <Link
                  to="/aboutus"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0,0,0,.55)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {" "}
                  About Us
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="/contactus"
                  style={{
                    textDecoration: "none",
                    color: "rgba(0,0,0,.55)",
                    whiteSpace: "nowrap",
                  }}
                >
                  {" "}
                  Contact Us
                </Link>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <button
        className="btn btn-success"
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          backgroundColor: domainDataSession
            ? domainDataSession.header_color
            : "#fff",
          borderColor: "white",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1rem",
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        Back to top <FaArrowUp />
      </button>
    </>
  );
}

export default Index;
