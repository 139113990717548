import React, { useEffect, useState } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { authProtectedRoutes } from "./Routes";
import AppRoute from "./Routes/route";
import Header from "./Components/Pages/Home/NavbarIndex";
import Footer from "./Components/Pages/Home/FooterIndex";
import { Suspense } from "react";
import Skeleton from "./Components/Pages/Home/Skeleton";
import Loader from "./Components/Ui/Loader";

function App() {
  const [currentPath, setCurrentPath] = useState("");

  React.useEffect(() => {
    setCurrentPath(window.location.pathname);
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPath]);

  return (
    <React.Fragment>
      <Route component={Header} />
      <Suspense
        fallback={
          <Loader
            customStyle={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        }
      >
        <Switch>
          {authProtectedRoutes.map((route, id) => (
            <AppRoute
              path={route.path}
              component={route.component}
              isAuthProtected={false}
              id={id}
              exact={route.exact}
              key={id}
            />
          ))}
        </Switch>
      </Suspense>

      <Route component={Footer} />
    </React.Fragment>
  );
}

export default App;
