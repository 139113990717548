// import {
//   GET_COUPON_BY_ID_FAILURE,
//   GET_COUPON_BY_ID_SUCCESS,
//   SET_COUPON_BY_ID_LOADING,
//   RESET_COUPON_BY_ID,
//   GET_COUPON_LIST_BY_WEBSITE_SUCCESS,
//   GET_COUPON_LIST_BY_WEBSITE_FAILURE,
//   GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE,
//   GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS
// } from "./actionTypes";
import * as actionTypes from "./actionTypes";

const initialState = {
  couponByID: {},
  activeStoresList: [],
  couponListByWebsite: [],
  couponByName: [],
  totalRecords: 0,
  totalPages: 0,
  per_page: 0,
  page: 0,
  error: null,
  errorMessage: "",
  success: false,
  successMessage: "",
  loading: false,
  search: {},
  getEndUser: [],
  getRecommendBanner: [],
};

const Coupon = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COUPON_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
        error: false,
      };

    case actionTypes.RESET_COUPON_BY_ID:
      return {
        ...state,
        couponByID: {},
      };

    case actionTypes.GET_COUPON_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: "",
        couponByID: action.payload,
      };
    case actionTypes.GET_COUPON_BY_ID_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        error: true,
        loading: false,
        couponByID: {},
      };
    case actionTypes.GET_COUPON_LIST_BY_WEBSITE_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: "",
        couponListByWebsite: action.payload.data,
        totalRecords: action.payload.totalRecords,
        totalPages: action.payload.total_pages,
      };
    case actionTypes.GET_COUPON_LIST_BY_WEBSITE_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        error: true,
        loading: false,
        couponListByWebsite: [],
      };
    case actionTypes.GET_ACTIVE_STORES_BASED_ON_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: "",
        activeStoresList: action.payload,
      };
    case actionTypes.GET_ACTIVE_STORES_BASED_ON_DOMAIN_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        error: true,
        loading: false,
        activeStoresList: [],
      };
    case actionTypes.GET_COUPON_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: "",
        couponByName: action.payload,
        totalRecords: action.totalRecords,
        totalPages: action.total_pages,
        per_page: action.per_page,
        page: action.page,
        search: action.search,
      };
    case actionTypes.GET_COUPON_BY_NAME_FAILURE:
      return {
        ...state,
        errorMessage: action.errorMessage,
        error: true,
        loading: false,
        couponByName: [],
      };
    case actionTypes.GET_END_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        getEndUser: action.payload,
      };
    case actionTypes.GET_END_USER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case actionTypes.RECOMMEND_BANNER_BY_DOMAIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        getRecommendBanner: action.payload,
      };
    case actionTypes.RECOMMEND_BANNER_BY_DOMAIN_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      };
    default:
      return { ...state };
  }
};

export default Coupon;
