import React, { useState } from 'react';
import { Link} from 'react-router-dom';
import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { FaHome, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa";
import { getImageUrl } from "../../../../Utils";

export default function Footr() {

  const { categoryList } = useSelector((state) => state.Category);
  const { domainData } = useSelector((state) => state.Website);

  const [visible, setVisible] = useState(false);
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  window.addEventListener("scroll", toggleVisible);

  return (
    <>
  {/* Footer */}
  <footer className=" text-center text-white"  style={{backgroundColor: "#005e36", color:"#c9f8de"}}>
    {/* Grid container */}
    <div className="container p-4">
      <section className="">
        {/*Grid row*/}
        <div className="row">
          {/*Grid column*/}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0 pt-2">
        <img
                src={getImageUrl(domainData?.logo, "logo512.png")}
                width="100"
                height="80"
                className="d-inline-block align-top"
                alt="logo"
                loading='lazy'
              />
              {/* <h6 className="text-uppercase mb-4 font-weight-bold">Casanova</h6> */}
              <p>
              {domainData?.footer_about_us}
              </p>
              </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Contact</h5>
            <ul className="list-unstyled mb-0">
              <li>
              <p>
                  <FaHome /> {domainData?.contact?.address}
                  </p>
              </li>
              <li>
              <p>
                  <FaEnvelope /> {domainData?.contact?.email}
                  </p>
              </li>
              <li>
              <p>
                 <FaPhoneAlt /> {domainData?.contact?.phone}
                 </p>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Categories</h5>
            <ul className="list-unstyled mb-0">
        {categoryList.slice(0, 3).map((category, key) => (
          <li>
          <p>
            <span component={Link} to={`/category/${category.category}`}>
              {category.category}
            </span>
            </p>
          </li>
          )
          )
        }
            </ul>
          </div>
          {/*Grid column*/}
          {/*Grid column*/}
          <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 className="text-uppercase">Navigate</h5>
            <ul className="list-unstyled mb-0">
              <li>
              <p>
                <Link
                  to="/stores"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Stores
                </Link>
                </p>
              </li>
              <li>
              <p>
                  <Link
                    to="/category"
                    id="category-dropdown"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {" "}
                    Categories
                  </Link>
                  </p>
              </li>
              <li>
              <p>
                <Link
                  to="/aboutus"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                  }}
                >
                  {" "}
                  About Us
                </Link>
                </p>
              </li>
            </ul>
          </div>
          {/*Grid column*/}
        </div>
        {/*Grid row*/}
      </section>
      {/* Section: Links */}
    </div>
    {/* Grid container */}
    {/* Copyright */}
    {/*<div
          className="text-center p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
        >
          © 2023 Copyright:
          <a className="text-white" >
            zizoze.com
          </a>
        </div>*/}
    {/* Copyright */}
  </footer>
  {/* Footer */}
    <button
        style={{
          position: "fixed",
          bottom: "10px",
          right: "20px",
          display: visible ? "inline" : "none",
          zIndex: "999",
          fontSize: "1rem",
          borderRadius:"50px",
          border:"2px solid #028950",
          color:"#028950"
        }}
        onClick={() => window.scrollTo(0, 0)}
      >
        <FaArrowUp/>
      </button>
</>

  )
}