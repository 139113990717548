import * as actionTypes from "./actionTypes";

export const sendContactDetailsAction = (data) => ({
  type: actionTypes.SEND_CONTACT_DETAILS,
  payload: data,
});

export const resetContactMessageAction = () => ({
  type: actionTypes.RESET_MESSAGE,
});
