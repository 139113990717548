import { all } from "redux-saga/effects";

import CategorySaga from "./Category/saga";
import CouponBasedOnDomain from "./CouponBaseOnDomain/saga";
import StoresSaga from "./Stores/saga";
import WebsiteSaga from "./Website/saga";
import ContactSaga from "./Contact/saga";
import Couponsaga from "./Coupons/saga";
import CouponUrlSaga from "./couponUrl/saga";
import NewsletterSaga from "./NewsLetter/saga";

export default function* rootSaga() {
  yield all([
    CategorySaga(),
    CouponBasedOnDomain(),
    StoresSaga(),
    WebsiteSaga(),
    ContactSaga(),
    Couponsaga(),
    CouponUrlSaga(),
    NewsletterSaga(),
  ]);
}
